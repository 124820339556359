import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import auth from '../store/reducers/auth';
import user from './reducers/users/users';

const appReducer = combineReducers({
  auth: auth,
  user: user
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    console.log('I am logging out');
    localStorage.clear();
    state = undefined
  }

  return appReducer(state, action)
}

let composeEnhancers = compose;

function storeToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch(err) {
    console.log(err);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState === null) return undefined
    return JSON.parse(serializedState);
  } catch(err) {
    console.log(err);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();


// if (__DEV__) {
//   composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// }
const configureStore = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

configureStore.subscribe(()=> {
  storeToLocalStorage(configureStore.getState())
})

export default configureStore;
