import * as Actions from '../../actions/actions';
// import {Alert} from 'react-native';
const initialState = {
  productsList: '',
  supplierList: '',
  inquiryList: '',
  inquirySuppliers: '',
  supplierProducts: '',
  inquiryDetail: ''
};
const users = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_USER_LIST:
      return {
        ...state,
        usersList: action.data,
      };

    case Actions.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.data,
      };
    case Actions.GET_PAGE_NO:
      return {
        ...state,
        pageNo: action.data,
      };
    case Actions.GET_LAST_EVALUATED_KEY:
      return {
        ...state,
        ESK: action.data,
      };
    case Actions.GET_USER_COMMENTS:
      return {
        ...state,
        comments: action.data,
      };
    case Actions.COMMENTS_NEXT_TOKEN:
      return {
        ...state,
        commentsNextToken: action.data,
      };
    case Actions.COMMENTS_EXPORT:
      return {
        ...state,
        csv: action.data,
      };
    default:
      return state;
  }
};
export default users;
