import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link
} from "react-router-dom";
import { createBrowserHistory } from "history"
import {Provider} from 'react-redux';
import './assets/vendor/fontawesome-free/css/all.min.css'
import './assets/css/sb-admin-2.css';
import './assets/css/custom.css';


const history = createBrowserHistory();
const ViewLogin = React.lazy(() =>
  import('./navigation/ViewLogin')
);
const ViewHome = React.lazy(() =>
  import('./navigation/ViewDashboard')
);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div className="loading" />}>
        <Router history={history}>
          <Switch>
            <Route path='/auth' component={ViewLogin} />
            <Route path='/home' component={ViewHome} />
            <Redirect exact from="/" to={"/auth"} />
          </Switch>
        </Router>
      </Suspense>
    );
  }

}

export default App;
