import * as Actions from '../actions/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {Alert} from 'react-native';
const initialState = {
  user: '',
  isLoading: false,
  preference: '',
};
const auth = (state = initialState, action) => {
  //  console.log('data from reduces',action.data)
  switch (action.type) {
    case Actions.MESSAGE:
      toast(action.message)
      return state;
    case Actions.UI_START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.UI_COMMENT_START_LOADING:
      return {
        ...state,
        isCommentLoading: true,
      };
    case Actions.UI_COMMENT_STOP_LOADING:
      return {
        ...state,
        isCommentLoading: false,
      };
    case Actions.UPDATE_USER:
      return {
        ...state,
        user: action.data,
      };
    case Actions.FORGOT_EMAIL:
      return {
        ...state,
        forgotEmail: action.data,
      };
    case Actions.GET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };
    case Actions.GET_PREFERENCE:
      return {
        ...state,
        preference: action.data,
      };
    case Actions.GET_USER_ID:
      return {
        ...state,
        userId: action.data,
      };

    default:
      return state;
  }
};
export default auth;
