export const MESSAGE = 'MESSAGE';
export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const UI_COMMENT_START_LOADING = 'UI_COMMENT_START_LOADING';
export const UI_COMMENT_STOP_LOADING = 'UI_COMMENT_STOP_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const FORGOT_EMAIL = 'FORGOT_EMAIL';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST';
export const GET_INQUIRY = 'GET_INQUIRY';
export const GET_INQUIRY_SUPPLIERS = 'GET_INQUIRY_SUPPLIERS';
export const GET_HOME_INFO = 'GET_HOME_INFO';
export const GET_SUPPLIER_PRODUCTS = 'GET_SUPPLIER_PRODUCTS';
export const GET_INQUIRY_DETAIL = 'GET_INQUIRY_DETAIL';
export const GET_PREFERENCE = 'GET_PREFERENCE';
export const GET_USER_ID = 'GET_USER_ID';
//// Orders

export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const UPDATE_CURRENT_ORDER = 'UPDATE_CURRENT_ORDER';
export const UPDATE_CURRENT_DOCUMENT = 'UPDATE_CURRENT_DOCUMENT';
export const UPDATE_DOCS = 'UPDATE_DOCS';

//Sample
export const GET_SAMPLE_DETAIL = 'GET_SAMPLE_DETAIL';
export const GET_SAMPLE_SUPPLIERS = 'GET_SAMPLE_SUPPLIERS';
export const GET_SAMPLE = 'GET_SAMPLE';



//// CTVS
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_PAGE_NO = 'GET_PAGE_NO';
export const GET_LAST_EVALUATED_KEY = 'GET_LAST_EVALUATED_KEY';
export const GET_USER_COMMENTS = 'GET_USER_COMMENTS';
export const COMMENTS_NEXT_TOKEN = 'COMMENTS_NEXT_TOKEN';
export const COMMENTS_EXPORT = 'COMMENTS_EXPORT';
